
.layout_header {
    position: fixed;
    top: 0;
    left: 50%;
    max-width: 550px;
    transform: translateX(-50%);
    background-color: var(--neutral-white);
    width: 100%;
    z-index: 2;
}

.layout_header .header_inner {
    height:56px ;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.layout_header .header_inner .header_title {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font: var(--p2);
    letter-spacing: var(--p2-letter-spacing);
    color: var(--neutral-800);

}
.layout_header  .header_right_btn{
    margin-left: auto;
}