/* 이미지 롤링 */
@keyframes roll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.image_item_rolling {
    margin-top: 20px;
    position: relative;
    z-index: 0;
}
.image_item_rolling .swiper {
    padding: 0 20px;
}

.image_item_rolling .swiper-slide {
    max-width: 118px;
    border-radius: 20px;
    aspect-ratio: 59 / 70;
    overflow: hidden;
}
.image_item_rolling .swiper-wrapper {
    transition-timing-function: linear;
}

.image_item_rolling .swiper-wrapper {
	transition-timing-function: linear;
}

.image_item_rolling img {
    width: 100%;
    height: 100%;
}

.main_title {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 550px;
    z-index: 1;
    display: flex;
    padding: 16px 20px 16px 20px;
    background-color: #F2F4F6;
}

.login_user {
    font: var(--h4);
    letter-spacing: var(--h4-letter-spacing);
}

.greeting_name {
    font-weight: 800;
    color: #01BCC8;
}

.title_icon {
    display: flex;
    gap: 16px;
    margin-left: auto;
}

.notification {
    gap: 8px;
    display: flex;
    align-items: center;
}

.icon_vector {
    margin-left: auto;
    cursor: pointer;
}

.notification_container {
    background: #FFFFFF;
    border-radius: 32px;
    padding: 20px;
}

.notification_title {
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #3C3D40;
    color: #FFFFFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.3px;
    width: 37px;
    height: 24px;
}

.notification_message {
    font: var(--body1);
    letter-spacing: var(--body1-letter-spacing);
    width: calc(100% - 72px);
}

.image_container {
    position: relative;
    text-align: center;
    margin-top: 12px;
}

.body_img {
    width: 100%;
    aspect-ratio: 13/14;
    border-radius: 32px;
    object-fit: cover;
}

.overlay_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
    line-height: 38.4px;
}

.overlay_title {
    font: var(--h1);
    letter-spacing: var(--h1-letter-spacing);
}

.image_section_container {
    border: 1px solid #FFFFFF;
    border-radius: 32px;
    background: #FFFFFF;
    margin-top: 12px;
    padding: 20px 0px 20px 0px;
}

.image_section {
    display: flex;
    align-items: center;
    gap: 14px;
    border: 1px solid #FFFFFF;
    border-radius: 32px;
    background: #FFFFFF;
    margin-top: 12px;
    padding: 20px;
}

.body_img2, .body_img3 {
    border-radius: 36px;
    width: 52px;
    height: 52px;
}

.text_title {
    font: var(--p1);
    letter-spacing: var(--p1-letter-spacing);
    color: #000000;
}

.text_subtitle {
    color: #7F8287;
    cursor: pointer;
    font: var(--body1-1);
    letter-spacing: var(--body1-letter-spacing);
    display: flex;
    align-items: center;
    padding-top: 3px;
}

.image_item {
    display: flex;
    gap: 14px;
    align-items: center;
}

.assessment_container {
    display: flex;
    gap: 8px;
    margin: 12px 0;
    justify-content: space-around;
}

.image_section_content {
    padding-left: 20px;
}

.general_assessment {

    padding: 20px;

    flex: 1 0 0;
    border-radius: 32px;
    background-color: #FFFFFF;
}

.assessment_category {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.3px;
    background: var(--primary-50, #DEFDFF);
    width: 37px;
    height: 24px;
}

.assessment_category_comprehensive {
    color: #1B2ED3;
    background: #DFE2FF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.3px;
    width: 37px;
    height: 24px;
}

.assessment_item_health_check {
    padding-top: 20px;
    color: var(--neutral-500);
    font: var(--p4);
    letter-spacing: var(--p4-letter-spacing);
}

.assessment_item {
    color: var(--neutral-black, #000);
    padding-top: 6px;
    font: var(--p1);
    letter-spacing: var(--p1-letter-spacing);
}

.main_login {
    font: var(--20-lable-extra);
    letter-spacing: var(--h4-letter-spacing);
    color: #9C9EA3;
}

.login_section, .main_arrow {
    display: flex;
    align-items: center;
}

.notification_arrow {
    margin-left: auto;
    width: 20px;
    height: 20px;
}

.button_wrapper {
    margin-top: 22px;
    display: flex;
    justify-content: center;
}

.text_icon_vector {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
}