
.profile-container {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    position: relative;

}

.profile-image {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 100%;

}

.profile-img {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;

}





.edit_label {
    font: var(--body1);
    letter-spacing: var(--body1-letter-spacing)
}


.edit_value {
    display: flex;
    align-items: flex-end;
    margin-top: 8px;
    padding: 10px 0;
    justify-content: space-between;
    gap: 10px;
    font: var(--body1-2);
    letter-spacing: var(--body1-letter-spacing);
    color: #B4B5B9;
}

.edit_nickname {
    color: var(--neutral-black);
}

.edit_section {
    margin-top: 24px;
    border-bottom: 1px solid #EEEEEF;
}
.edit_section:first-of-type{
    margin-top: 0;
}
/* -------NicknameEdit---------- */
.nickname_instruction {
    font:var(--h2);
    letter-spacing:var(--h2-letter-spacing)
}

.nickname_policy {
    font:var(--body1-2);
    letter-spacing:var(--body1-letter-spacing);
    color: #7F8287;
    margin-top: 8px;
}

.nickname_label {
    font:var(--body1);
    letter-spacing:var(--body1-letter-spacing);
    color: #212223;
}



.nickname_input_container {
    margin-top: 24px;
}

/* --------PasswordSuccess ---------- */
.success_body {
    text-align: center;
    padding: 176.5px 0px 280.5px 0px;
}

.success_text {
    font:var(--h3);
    letter-spacing: var(--h3-letter-spacing);
}

.safety_message {
    font:var(--body1-2);
    letter-spacing: var(--body-letter-spacing);
    color: #7F8287;
    padding-top: 4px;
}