.login_input{
    border: 1px solid #3C3D40;
    box-sizing: border-box;
    width: 100%;
    padding: 17px 14px;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
    line-height: 14px;
}
.auto_login{
    display: flex;
    align-items: center;
    gap: 6px;
    height: 36px;
    margin-top: 8px;
}
.account_actions{
    display: flex;
    height: 46px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}
.account_action{
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    color: #686B70;
    text-decoration: none;
}
.sns_login_buttons{
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 16px;
}
.sns-login-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.85px;
    color: #686B70;
}
.auto_login_title{
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #212223;
}
.sns-login-container:before, .sns-login-container:after{
    content: "";
    height: 1px;
    background: #EEEEEF;
    width: 100%;
}
.sns-login-container span {
    white-space: nowrap; 
    margin: 0px 12px;
}
.login_footer{
    margin-top: 40px;
}
.resend_time{
    padding: 7.5px 12px;
    border-radius: 8px;
    font:var(--caption1);
    letter-spacing: var(--caption1-letter-spacing);
    color: var(--primary-800);
    background-color: var(--primary-50);
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
}
.NaverLogin, .KakaoLogin{
    width: 44px;
    height: 44px;
}
.login_input:focus {
    border: 1px solid #29C5CE !important;
}
.login_logo_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;  
    margin-top: 8px;
}

.login_logo_image {
    position: relative;
    max-width: 95.37px;
    margin: 0 auto;  
}

.MainLogo {
    width: 100%;
    aspect-ratio: 867 / 800;
    object-fit: cover;
}
.login_logo_text_container{
    margin-top: 12px;
}
.login_logo_text{
    padding: 4px 1px 0px 1px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.01em;
}
.login_logo_title{
    font-size: 23px;
    font-weight: 900;
    line-height: 23px;
    padding-top: 6px;
}
.success_icon_wrapper{
    position: relative;
    max-width: 38.4px;
}
.Success_Img{
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
.success_message{
    font-size: 22px;
    font-weight: 700;
    line-height: 29.48px;
    letter-spacing: -0.025em;
    margin-top: 8px;
}
.found_userid{
    color: #01BCC8;
}
.success_message_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.success_image_container{
    margin-top: 24px;
}
.fail_image_container{
    position: relative;
    max-width: 56px;
}
.fail_img{
    width: 100%;
    aspect-ratio: 1 / 1 ;
    object-fit: cover;
}
.checkbox_container{
    width: 20px;
    height: 20px;
}


/* 아이디 찾기 */
.passwordsuccess.sns .success_image_container{
    margin-top: 12px;
}
.sns_user_con {
    border-radius: 50em;
    width: 100%;
    background: var(--neutral-10);
    padding: 16px;
    display: flex;
    gap: 12px;
    margin-top: 24px;
}

.sns_user_con .sns_user_icon {
    width: 44px;
    height: 44px;
}

.sns_user_con .sns_user_info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 2px;
}
.sns_user_con .sns_user_info .title{
    color:#002325;
    font:var(--p4);
    letter-spacing:var(--p4-letter-spacing)
}
.sns_user_con .sns_user_info .group_line{
    font:var(--12-label-md);
    letter-spacing: var(--caption1-letter-spacing);
    color:var(--neutral-600)

}