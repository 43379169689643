
.back_icon {
    margin-right: auto;
    cursor: pointer;
}

.title_text {
    flex-grow: 1;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 23.8px;
    color: #3C3D40;
}

.image_assimilation {
    width: 100%;
    height: 312px;
}

.description_section, .friends_step {
    margin-top: 24px;

}

.description_title {
    padding: 0 4px;
    font: var(--h2);
    letter-spacing: var(--h2-letter-spacing);
    color: #212223;
}

.description_text, .solution_text, .description_content {
    padding: 0 4px;
    font: var(--p4-1);
    letter-spacing: var(--p4-letter-spacing);
    color: #686B70;

}

.solution_text, .description_content, .steps_container {
    padding-top: 10px;
}

.section_title {
    padding: 0 4px;
    font: var(--p1);
    letter-spacing: var(--p1-letter-spacing);
}

.impact_title {
    font:var(--p1);
    letter-spacing: var(--p1-letter-spacing);
    padding: 16px 4px 0 ;
}

.steps_row {
    display: flex;
    gap: 8px;
}

.step {
    border: 1px solid #FFFFFF;
    border-radius: 32px;
    padding: 20px;
    background: #F8F8F9;
    width: 100%;
    box-sizing: border-box;
}

.step_title {
    font:var(--body2);
    letter-spacing: var(--body2-letter-spacing);
    color: #01BCC8;
}

.step_description {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    color: #000000;
    padding-top: 16px;
}

.step_example {
    font-size: 14px;
    font-weight: 500;
    line-height: 23.3px;
    color: #686B70;
}

.positive_impact_container {
    margin-top: 40px;
}

.description_text {
    margin-top: 24px;
}

.assimilation_image {
    width: 100%;
}

.kikid_friends {
    margin-top: 40px;
}

.kikid_description {
    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    padding-top: 24px;
    color: #686B70;
}

.impact_step {
    margin-top: 24px;
}

.friends_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    color: #000000;
}

.friends_content {
    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    padding-top: 10px;
    color: #686B70;
}
/* ----동화 검사 페이지 ---- */
.story_header{
    padding: 16px 20px;
    display: flex;
}
.Bag_Img, .Menu_Img{
    width: 24px;
    height: 24px;
}
.image_container{
    display: flex;
    margin-left: auto;
    gap: 16px;
}
.story_header_text {
    font-size: 20px;
    font-weight: 700;
    line-height: 120%; 
    letter-spacing: var(--h4-letter-spacing);
}
.total_books{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.4px;
    color: #000000;
}
.books_number{
    color: #01BCC8;
}
.book_image {
    position: relative;
    text-align: center;
}
.Story_Img {
    width: 100%;
    aspect-ratio: 78 / 47; 
    border-radius: 32px; 
    object-fit: cover; 
}
.book_info{
    margin-top: 24px;
}
.parent_child-books{
    color: #686B70;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
    padding-top: 8px;
}
.book_image{
    margin-top: 24px;
}
.test_type_selection{
    margin-top: 40px;
}
.test_type_options{
    display: flex;
    gap: 8px;
    margin-top: 10px;
}
.test_type{
    width: 100%;
    background: #DEFDFF;
    padding: 40px 20px 20px 20px;
    border-radius: 32px;
}
.hidden_test_type {
    visibility: hidden; 
}
.test_type_container{
    padding-top: 10px;
}
.test_type_title{
    color:#000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    letter-spacing: -0.45px;
}
.test_type_count{
    padding-top: 6px;
    color:#057A7F;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
}
.call_type_count{
    padding-top: 6px;
    font-size: 15px;
    font-weight: 500;
    line-height: 21.75px;
    letter-spacing: -0.025em;
    color: #686B70;
}
.StoryTestPage .main_title {
    background-color: var(--neutral-white);
}
.test_type_header{
    color: var(--neutral-black);
    font: var(--p3);
    letter-spacing: var(--tiny1-letter-spacing);
}
.vertical-bar{
    display: inline-block; 
    width: 1px;         
    height: 11px;         
    background-color: var(--primary-800);
    margin: 0px 6px;
}

/* --동화검사 결제 ---  */
.intro_call_info{
    font-size: 15px;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
    color: #7F8287;
}
.Call_arrow_img{
    width: 20px;
    height: 20px;
}
.call_type_container{
    display: flex;
    padding: 20px 24px 0px 24px;
}
.call_type_title{
    font: var(--p1);
    line-height: 22.68px;
    letter-spacing: -0.025em;
}

.call_arrow_container{
    margin-left: auto;
}
.call_img_data {
    display: flex;
    gap: 8px; 
    max-width: 100vw;
    -ms-overflow-style: none; 
    overflow-x: auto; 
    padding-left: 24px;
    padding-top: 16px;
}
.Call_img {
    max-width: 118px;
    height: auto;
    aspect-ratio: 59 / 70; 
    border-radius: 20px; 
    /* object-fit: cover; */
}

.call_img_data::-webkit-scrollbar {
    display: none; 
}
.info_wrapper{
    padding: 8px 24px 0px 24px;
}
.payment_line{
    height: 1px;
    width: 100%;
    background: #F8F8F9;
    margin-top: 20px;
}
.payment_body{
    padding: 0px 24px;
    margin-bottom: 15vh;
}
.CallSinglePayment-instruction{
    font:var(--p3);
}
.CallSinglePayment-series{
    width: 100%;
    background-color: #ABF2F6;
    padding: 40px 20px 20px 20px;
    border-radius: 32px;
}
.CallSinglePayment-series-title{
    font: var(--p1);
    color: var(--neutral-black);
}
.CallSinglePayment-select-all{
    font: var(--p4);
    color: var(--primary-800);
    padding-top: 6px;
}

.Payment_img{
    width: 100%;
}
.CallSinglePayment-selection{
    margin-top: 20px;
}
.CallSinglePayment-images, .CallSinglePayment-image-row{
    margin-top: 8px;
}

.CallSinglePayment-image-row{
    display: flex;
    width: 100%;
    gap: 8px;
}
.CallSinglePayment-image-row a {
    width: calc(50% - 4px) !important; /* gap의 절반을 뺀 값 */
}

.CallSinglePayment-image {
    width: 100%;
}
.Payment_img {
    width: 100%;
    aspect-ratio: 38 / 45;
    object-fit: cover;
}
.price-info{
    display: flex;
    padding: 0px 24px;
}
.price-label{
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.025em;
}
.price-value{
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.025em;
    margin-left: auto;
}
.payment_info{
    display: flex;
}
.payment_image_container{
    position: relative;
    max-width: 84px;
    margin-left: auto;
}
.payment_img{
    aspect-ratio: 21 / 25;
    border-radius: 17.778px;
}
.payment_title{
    font-size: 13px;
    font-weight: 700;
    line-height: 18.85px;
    letter-spacing: -0.025em;
    color: #01BCC8;
}
.assessment_subtitle{
    color: var(--neutral-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.5px;
}
.call_type_details{
    color: var(--neutral-600);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.3px;
}
.payment_type_details{
    color: var(--neutral-600);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
    margin-top: 6px;
}

.payment_title_container{
    padding: 8px 0px;
}
.payment_content{
    color: var(--neutral-600);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
    margin-top: 16px;
}
/* ---장바구니--- */
.shopping_cart_options{
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}
.remove-item, .select-all{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.35px;
    cursor: pointer;
}
.remove-item{
    color: var(--neutral-400);
}
.select-all{
    color: var(--neutral-900);
}
.cart_info{
    background: var(--primary-50);
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 8px;
    margin-top: 8px;
}
.item_image{
    max-width: 84px;
    position: relative;
}
.shopping_img{
    aspect-ratio: 21 / 25;
    border-radius: 16px;
    object-fit: cover;
}
.cart_item{
    display: flex;
    gap: 16px;
    border-bottom: 1px solid var(--neutral-10);
    padding: 20px 0px;
}
.item_title{
    font: var(--body2);
    color: var(--primary-500);
}
.item_description{
    color: var(--neutral-black);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.5px;
    padding-top: 4px;
}
.item_check{
    max-width: 24px;
    position: relative;
    margin-left: auto;
}
.check-img{
    width: 100%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
}
.item_details{
    padding: 8px 0px;
}
.cart-info-item{
    list-style: disc;
    font: var(--p4-1);
    letter-spacing: var(--p4-letter-spacing);
    color: #002325;
}
.cart-info-item::before {
    content: '•';
    color: #002325; 
    font-size: 15px; 
    padding-right: 10px;
}
.report_results{
    margin-top: 24px;
    font: var(--p4-1);
    letter-spacing: -0.025em;
    color: var(--neutral-600);
}
.CompleteImg_container{
    position: relative;
    margin-top: 24px;
}
.CompleteImg{
    width: 100%;
    aspect-ratio: 312 / 371;
    border-radius: 32px;
    object-fit: cover;
}
/* -- 비디오 플레이어  --- */
.video_header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
}
.video_title{
    flex: 1;  /* 남은 공간 차지 */
    text-align: center;  /* 텍스트 중앙 정렬 */
    font: var(--p2);
}
.videoPlayer_close{
    flex-shrink: 0;  /* 크기 고정 */
}
.refund_btn{
    margin-top: 16px;
    font: var(--p4-1);
    color: var(--primary-500);
    text-decoration: underline;
    cursor: pointer;
}
.promo-banner{
    border-radius: 16px;
    padding: 16px;
    background-color: #DEFDFF;
    text-align: center;
    margin: 8px 24px 0px 24px;
}
.promo-title{
    font-size: 22px;
    font-weight: 700;
    line-height: 29.48px;
    letter-spacing: -0.025em;
    color: #002325;
}
.promo-description{
    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    letter-spacing: -0.025em;
    color: var(--primary-800);
    margin-top: 5px;
}