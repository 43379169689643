/* D5 */
.reportDetail-box .sub-title:first-child {
  border-top: none;
}

.reportDetail-box {
  width: 487px;
  font-size: 10px;
  background-color: #fff;
  page-break-inside: auto;
}


.reportDetail-box .detail-wrap {
  display: flex;

}

.reportDetail-box .detail-wrap>div {
  flex: 1;
}
.left-box{
  border-right: 1px solid #DEDFE0;
}
.bg_sky {
  height: 24px;
  padding: 7px 16px;
  background: #F2FAFF;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: -0.25px;
  border-top: 1px solid #DEDFE0;
  border-bottom: 1px solid #DEDFE0;
}



.img-box{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 24px);
  padding:12px;
}
.img-box figure{
  width: 181px;
  height: 108px;
}
.desc_w {
  color: #000;
  padding: 12px;
  font-size: 10px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.25px;
  background: #fff;
  text-align: left;
  /* border-top: 1px solid #DEDFE0; */

}



/* E1 */
.detailE1 .box_text {
  border-top: none;
}



/* E2 */

.reportDetail-table {
  width: 487px;
  font-size: 10px;
  background-color: #fff;
  page-break-inside: auto;
}

.reportDetail-table table {
  width: 100%;
  border-collapse: collapse;
}

.reportDetail-table table thead {
  width: 100%;
  height: 24px;
  background-color: #fff;
}

.reportDetail-table th {
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #DEDFE0;
  padding: 7px 16px;
}

.reportDetail-table td {
  padding: 12px;
  text-align: center;
  background-color: #F7F7F7;
  vertical-align: middle;
  border: 1px solid #DEDFE0;
  font-weight: 400;
  letter-spacing: -0.025em;
  text-align: center;
}

.reportDetail-table th:first-child,
.reportDetail-table td:first-child {
  border-left: none;
}

.reportDetail-table th:last-child,
.reportDetail-table td:last-child {
  border-right: none;
}

.detailE2 th {
  text-align: center;
  font-size: 10px;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: -0.25px;
  color: #23AD75;

}

.detailE2 th:first-child {
  color: #6E28A8;
}




/* E3 */
.detailE3 table th:nth-child(3){
  width: 104px;
}
.detailE3 table tbody tr:first-child td {
  background: #224054;
  color: #FFF;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.25px;
  width: 48px;
}

.detailE3 table tbody tr:nth-child(2) td:first-child {
  width: 50px;
  color: #6E28A8;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.25px;
}

.detailE3 table tbody tr:nth-child(3) td:first-child {
  color: #23AD75;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.25px;
}
.detailE3 table tbody tr:last-child td:first-child {
  line-height: 150%;
  letter-spacing: -0.25px;
}