/* 사이드 메뉴 효과 */
.slide-enter {
    transform: translateX(100%);
    opacity: 0;
}

.slide-enter-active {
    transform: translateX(0);

    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}


.slide-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

/* NavBar 스타일 */
.navbar {
        width: 82%;
    height: 100vh;
    /*background-color: #333;*/
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2001;
}

.NavBar_container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 550px;
    background: #FFFFFF;
    box-shadow: 0px -14px 4px 0px rgba(35, 37, 53, 0.00), 0px -9px 4px 0px rgba(35, 37, 53, 0.00), 0px -5px 3px 0px rgba(35, 37, 53, 0.02), 0px -2px 2px 0px rgba(35, 37, 53, 0.03), 0px -1px 1px 0px rgba(35, 37, 53, 0.03);
}

.nav_items {
    display: flex;
    padding: 6px 0px 2px 0px;
    align-items: center;
    justify-content: space-around;
}

.nav_label {
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    color: #9C9C9C;
    text-align: center;
    padding-top: 4px;
}

.nav_icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab_line_container {
    text-align: center;
    padding: 5px;
}

.app_container {
    position: relative;
}

.tab_icon_small {
    width: 24px;
    height: 24px;
}

.tab_icon_large {
    width: 48px;
    height: 48px;
}

.item_container {
    width: 20%;
    height: 100%;
}
