.toggle_switch {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 26px;

}
.toggle_switch input[type="checkbox"] {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    font-size: initial;
    clip: rect(0 0 0 0);


}
input[type="checkbox"]:checked + .slider {
    background-color: var(--primary-500);
}
input[type="checkbox"]:checked + .slider::before {
    transform: translateX(13px);
}

.slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--neutral-200);
    border-radius: 50em;
    cursor: pointer;
    transition: 0.4s;
}
.slider::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    background-color:var(--neutral-white);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    transition: 0.4s;
}
.setting_box{
    padding: 8px 24px;
    border-bottom: 1px solid var(--neutral-10);
}

.setting_box .setting_title{
    color:var(--neutral-600);
    font:var(--body2-1);
    letter-spacing: var(--body2-letter-spacing);
    line-height: 48px;
}
.setting_box .setting_list .setting_label{
    width: 100%;
    text-align: left;
    color:var(--neutral-black);
    text-decoration: none;
    font:400 15px/48px 'Pretendard';
    letter-spacing: var(--p3-letter-spacing);
}
.setting_box .setting_list .setting_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
}

.setting_box .setting_list  .app_ver{
    color:var(--neutral-600);
    font:400 13px/100% 'Pretendard';
    letter-spacing: var(--body2-letter-spacing);
}

.setting_box .setting_list [type="radio"] {
    appearance: none;
    width: 15px;
    height: 15px;
    box-shadow: 0 0 0 1px var(--neutral-200);
    border-radius: 50%;
}
.setting_box .setting_list [type="radio"]:checked {
    box-shadow: 0 0 0 1px #01BCC8;
    border: 3px solid #ffffff;
    background-color: #01BCC8;
}
/*회원탈퇴 */
.withdrawal_section .content_body{

    display: flex;
    flex-direction: column;
    gap:24px;
}
.withdrawal_section .title_box{
    padding-right: 24px; ;
    display: flex;
    flex-direction: column;
    gap:10px;
}
.withdrawal_section .title{
    font:var(--h2);
    letter-spacing: var(--h2-letter-spacing);
}

.withdrawal_section .sub_para{
    font:var(--body1-2);
    letter-spacing: var(--body1-letter-spacing);
    color:var(--neutral-500);
}
.withdrawal_box{
    padding: 0;
    border:none;
}
.withdrawal_box .sub_title{

    font:var(--p3);
    letter-spacing: var(--p3-letter-spacing);
    margin-bottom: 16px;
}