@font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Black.woff2') format('woff2'),
         url('/public/fonts/Pretendard-Black.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src: url('/public/fonts/Pretendard-ExtraBold.woff2') format('woff2'),
         url('/public/fonts/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Bold.woff2') format('woff2'),
         url('/public/fonts/Pretendard-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-display: swap;
    src: url('/public/fonts/Pretendard-SemiBold.woff2') format('woff2'),
         url('/public/fonts/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Medium.woff2') format('woff2'),
         url('/public/fonts/Pretendard-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Regular.woff2') format('woff2'),
         url('/public/fonts/Pretendard-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Light.woff2') format('woff2'),
         url('/public/fonts/Pretendard-Light.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-display: swap;
    src: url('/public/fonts/Pretendard-ExtraLight.woff2') format('woff2'),
         url('/public/fonts/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Thin.woff2') format('woff2'),
         url('/public/fonts/Pretendard-Thin.woff') format('woff');
}

/* @font-face {
    font-family: 'Apple Icons';
    src: url('../fonts/appleicons_text.woff') format('woff'),
         url('../fonts/appleicons_text.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */
