.icon {
    width: 24px;
    height: 24px;
}


.overlay_button {
    display: flex;
    width: 172px;
    height: 56px;
    min-width: 56px;
    padding: 20px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background: #01BCC8;
    color: #FFFFFF;
    border: none;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.4px;
}

.btnTab_container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 550px;
    z-index: 1000;
    background: #FFFFFF;
}

.button_container {
    width: 100%;
    padding: 8px 20px 8px 20px;
    text-align: center;
    background: #FFFFFF;
}

.custom_button {
    width: 100%;
    padding: 20px 16px;
    background: #01BCC8;
    border: none;
    border-radius: 32px;
    color: #FFFFFF;
    font: var(--p3);
    letter-spacing: (--p3-letter-spacing);
    line-height: var(--label-line-height);
}

.submit_button {
    background: #01BCC8;
    width: 100%;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    border: none;
    padding: 20px 16px;
    border-radius: 32px;
}

.button {
    width: 100%;
    border-radius: 14px;
}

.Edit_btn_change {
    max-width: 550px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 8px 20px;
    background: var(--neutral-white);
}

.Edit_btn_complete {
    max-width: 550px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 8px 20px;
    display: flex;
    gap: 8px;
}


.button_section {
    max-width: 550px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 8px 20px;
    display: flex;
    gap: 8px;
}

.error_btn_change {
    max-width: 550px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 8px 20px;
    display: flex;
    gap: 8px;
}

.resend_button {
    background-color: #F4F4F5;
    color: #3C3D40;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    padding: 9.5px 12px;
    font: var(--body2-1);
    letter-spacing: var(--body2-letter-spacing);
    line-height: var(--label-line-height);

}


.back_btn {
    display: block;
    width: 24px;
    height: 24px;
    background: url(../../assets/img/back.png) no-repeat center;
    background-size: contain;
}

.dot_btn {
    display: block;
    width: 16px;
    height: 16px;
    background: url(../../assets/icon/icon-n700-dot.png) no-repeat center;
    background-size: contain;
}

.close_btn {
    display: block;
    width: 24px;
    height: 24px;
    background: url(../../assets/icon/icon-n900-close.png) no-repeat center;
    background-size: contain;

}

.right_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right_icon:after {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.right_arrow_btn:after {
    width: 20px;
    height: 20px;
    background-image: url(../../assets/icon/icon-n400-arrow.png);

}

.left_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left_icon:before {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.board_file_btn:before {
    width: 20px;
    height: 20px;
    background-image: url(../../assets/icon/icon-n600-file.png);

}

.show_pw_btn {
    width: 20px;
    height: 20px;
    background: url(../../assets/icon/icon-off-show.png) no-repeat center/ contain;

    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
.login_input:focus ~ .show_pw_btn, .login_input:focus ~ .show_pw_btn.show{
    opacity: 1;
}
.show_pw_btn.show {

    background-image: url(../../assets/icon/icon-on-show.png)
}


.sns_btn {
    width: 100%;
    border-radius: 50em;
    font: var(--p3);
    letter-spacing: var(--p3-letter-spacing);
    height: 56px;
    border: 1px solid var(--neutral-700);
    background-color: var(--neutral-700);
    padding-inline:  24px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color:var(--neutral-white);
}
.sns_btn:before{
    width: 24px;
    height: 24px;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:24px;
}
.sns_btn.apple{
    color:var(--neutral-black);
    border-color: var(--neutral-black);
    background-color: var(--neutral-white);
}
.sns_btn.kakao{
    color:var(--neutral-black);
    border-color: #FBE84C;
    background-color:#FBE84C;
}
.sns_btn.naver{
    border-color: #00C63B;
    background-color:#00C63B;
}
.sns_btn.apple:before{
    background-image: url(../../assets/icon/icon-sns-apple.png);
}
.sns_btn.kakao:before{
    background-image: url(../../assets/icon/icon-sns-kakao.png);
}
.sns_btn.naver:before{
    background-image: url(../../assets/icon/icon-sns-naver.png);
}