.counsel_title{
    color:var(--neutral-black);
    font:700 20px/120% 'Pretendard';
    text-decoration: none;
}
.info_container, .intro_description, .intro_details, .submit_button_container{
    margin-top: 24px;

}
.intro_title{
    font:var(--h1);
    letter-spacing: var(--h1-letter-spacing);
    color:var(--neutral-900);
    padding: 0 4px;
}
.intro_description, .intro_details{
    font:var(--p4-1);
    letter-spacing: var(--p4-letter-spacing);
    color: #686B70;
    padding: 0 4px;
}
.info_container_title{
    padding: 0 4px;
    font:var(--p1);
    letter-spacing: var(--p1-letter-spacing);
}
.content_label{
    color: #01BCC8; 
}
.MindPrescription .main_title{
    background-color:var(--neutral-white);
}
.MindPrescription  .image_container{
    margin-top: 0;
}
