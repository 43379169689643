/* 기본 */
/* 1지선다 기본 스타일 */
.choice-3, .choice-2 {
    top: 57.7% !important;
}
/* 4지선다 기본 */
.choice-4:nth-child(2){
    top: 48.7% !important;
}
.choice-4:nth-child(3){
    top: 53.2% !important;
}
.choice-4:nth-child(4){
    top: 57.8% !important;
}
/* 4지선다 seq_65번 일 경우 기본*/
.choice-4[data-goods-seq="65"]:nth-child(1) { top: 47.5% !important; }
.choice-4[data-goods-seq="65"]:nth-child(2) { top: 47.5% !important; }
.choice-4[data-goods-seq="65"]:nth-child(3) { top: 58.8% !important; }
.choice-4[data-goods-seq="65"]:nth-child(4) { top: 58.8% !important; }

/* 4지선다 seq_70번 일 경우 기본*/
.choice-4[data-goods-seq="70"]:nth-child(1) { top: 44.4% !important; }
.choice-4[data-goods-seq="70"]:nth-child(2) { top: 50% !important; }
.choice-4[data-goods-seq="70"]:nth-child(3) { top: 55.7% !important; }
.choice-4[data-goods-seq="70"]:nth-child(4) { top: 61.5% !important; }

/* 4지선다 seq_70번 일 경우 기본*/
.choice-4[data-goods-seq="77"]:nth-child(1) { top: 39.6% !important; }
.choice-4[data-goods-seq="77"]:nth-child(2) { top: 45% !important; }
.choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.3% !important; }
.choice-4[data-goods-seq="77"]:nth-child(4) { top: 55.6% !important; }


/* 6지선다 기본 스타일 */
.choice-6:nth-child(2) {
  top: 47.8% !important;
}
.choice-6:nth-child(3) {
    top: 52% !important;
  }
  .choice-6:nth-child(4) {
    top: 56% !important;
  }

/* 2, 3지선다 반응형 스타일 */
@media screen and (max-width: 550px) {
    .choice-3, .choice-2 {
        height: 12% !important;
    }
}

@media screen and (max-width: 458px) {
    .choice-3, .choice-2 {
        height: 10.5% !important;
        top: 56.5% !important;
    }
}

@media screen and (max-width: 393px) {
    .choice-3, .choice-2 {
        height: 8.7% !important;
        top: 55.2% !important;
    }
}

@media screen and (max-width: 414px) {
    .choice-3, .choice-2 {
        height: 9.5% !important;
        top: 55.8% !important;
    }
}

@media screen and (max-width: 330px) {
    .choice-3, .choice-2 {
        height: 7.5% !important;
        top: 54.5% !important;
    }
}

@media screen and (max-width: 270px) {
    .choice-3, .choice-2 {
        height: 6.3% !important;
        top: 54% !important;
    }
} 

/* 4지선다 반응형 스타일 */
 @media screen and (max-width: 458px) {
    .choice-4:nth-child(1) { top: 43.6% !important; }
    .choice-4:nth-child(2) { top: 48.8% !important; }
    .choice-4:nth-child(3) { top: 52.8% !important; }
    .choice-4:nth-child(4) { top: 56.5% !important; }
    .choice-4 {
        height: 3.1% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 45.5% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 50% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 54.8% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 59.5% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 57.7% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 57.7% !important; }
    .choice-4[data-goods-seq="65"]{
        width: 10.5% !important;
        height: 8% !important;
    }
    /* seq_77번 일 경우 기본형 */
    .choice-4[data-goods-seq="77"]:nth-child(1) { top: 41.7% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(2) { top: 46.2% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.7% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(4) { top: 55.2% !important; }
}

@media screen and (max-width: 430px) {
    .choice-4:nth-child(1) { top: 44% !important; }
    .choice-4:nth-child(2) { top: 48.85% !important; }
    .choice-4:nth-child(3) { top: 52.7% !important; }
    .choice-4:nth-child(4) { top: 56.2% !important; }
    .choice-4 {
        height: 2.9% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 45.4% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 50% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 54.6% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 59% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 57.3% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 57.3% !important; }
    .choice-4[data-goods-seq="65"]{
        height: 7.7% !important;
    }
    /* seq_77번 일 경우 기본형 */
    .choice-4[data-goods-seq="77"]:nth-child(1) { top: 42.3% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(2) { top: 46.4% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.6% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(4) { top: 54.8% !important; }
}

@media screen and (max-width: 414px) {
    .choice-4:nth-child(1) { top: 44.2% !important; }
    .choice-4:nth-child(2) { top: 48.9% !important; }
    .choice-4:nth-child(3) { top: 52.6% !important; }
    .choice-4:nth-child(4) { top: 56% !important; }
    .choice-4 {
        height: 2.8% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 45.7% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 50.1% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 54.2% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 58.8% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 57.1% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 57.1% !important; }
    .choice-4[data-goods-seq="65"]{
        height: 7.5% !important;
    }
    /* seq_77번 일 경우 기본형 */
    .choice-4[data-goods-seq="77"]:nth-child(1) { top: 42.6% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(2) { top: 46.6% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.6% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(4) { top: 54.7% !important; }
}

@media screen and (max-width: 393px) {
    .choice-4:nth-child(1) { top: 44.2% !important; }
    .choice-4:nth-child(2) { top: 49% !important; }
    .choice-4:nth-child(3) { top: 52.4% !important; }
    .choice-4:nth-child(4) { top: 55.5% !important; }
    .choice-4 {
        height: 2.6% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 45.8% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 50% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 54% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 58.3% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 47.9% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 56.5% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 56.5% !important; }
    .choice-4[data-goods-seq="65"]{
        /* width: 10.5% !important; */
        height: 7% !important;
    }
     /* seq_77번 일 경우 기본형 */
     .choice-4[data-goods-seq="77"]:nth-child(1) { top: 42.8% !important; }
     .choice-4[data-goods-seq="77"]:nth-child(2) { top: 46.7% !important; }
     .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.4% !important; }
     .choice-4[data-goods-seq="77"]:nth-child(4) { top: 54.3% !important; }
}

@media screen and (max-width: 375px) {
    .choice-4:nth-child(1) { top: 45% !important; }
    .choice-4:nth-child(2) { top: 49.1% !important; }
    .choice-4:nth-child(3) { top: 52.2% !important; }
    .choice-4:nth-child(4) { top: 55.1% !important; }
    .choice-4 {
        height: 2.5% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 46% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 49.8% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 53.8% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 57.8% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 48% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 48% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 56% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 56% !important; }
    .choice-4[data-goods-seq="65"]{
        height: 6.5% !important;
    }
    /* seq_77번 일 경우 기본형 */
    .choice-4[data-goods-seq="77"]:nth-child(1) { top: 43.3% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(2) { top: 46.9% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.6% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(4) { top: 54.2% !important; }
}

@media screen and (max-width: 330px) {
    .choice-4:nth-child(1) { top: 45.5% !important; }
    .choice-4:nth-child(2) { top: 49.3% !important; }
    .choice-4:nth-child(3) { top: 52% !important; }
    .choice-4:nth-child(4) { top: 54.7% !important; }
    .choice-4 {
        height: 2.4% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 46.5% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 49.9% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 53.4% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 56.8% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 48.2% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 48.2% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 55.5% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 55.5% !important; }
    .choice-4[data-goods-seq="65"]{
        /* width: 10.5% !important; */
        height: 6% !important;
    }
    /* seq_77번 일 경우 기본형 */
    .choice-4[data-goods-seq="77"]:nth-child(1) { top: 43.7% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(2) { top: 46.9% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50.2% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(4) { top: 53.5% !important; }
}

@media screen and (max-width: 270px) {
    .choice-4:nth-child(1) { top: 46.2% !important; }
    .choice-4:nth-child(2) { top: 49.3% !important; }
    .choice-4:nth-child(3) { top: 51.6% !important; }
    .choice-4:nth-child(4) { top: 53.8% !important; }
    .choice-4 {
        height: 2% !important
    }
    /* seq_70번 일 경우 기본형 */
    .choice-4[data-goods-seq="70"]:nth-child(1) { top: 47% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(2) { top: 49.7% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(3) { top: 52.8% !important; }
    .choice-4[data-goods-seq="70"]:nth-child(4) { top: 55.6% !important; }
    /* seq_65번 일 경우 기본형 */
    .choice-4[data-goods-seq="65"]:nth-child(1) { top: 48.5% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(2) { top: 48.5% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(3) { top: 54.3% !important; }
    .choice-4[data-goods-seq="65"]:nth-child(4) { top: 54.3% !important; }
    .choice-4[data-goods-seq="65"]{
        /* width: 10.5% !important; */
        height: 5% !important;
    }
    /* seq_77번 일 경우 기본형 */
    .choice-4[data-goods-seq="77"]:nth-child(1) { top: 44.8% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(2) { top: 47.5% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(3) { top: 50% !important; }
    .choice-4[data-goods-seq="77"]:nth-child(4) { top: 52.8% !important; }
} 


/* 5지선다 반응형 스타일 */
@media screen and (max-width: 458px) {
    .choice-5:nth-child(1) { top: 44.9% !important; left: 2.4% !important; }
    .choice-5:nth-child(2) { top: 48.6% !important; left: 2.4% !important; }
    .choice-5:nth-child(3) { top: 52.4% !important; left: 2.4% !important; }
    .choice-5:nth-child(4) { top: 56.3% !important; left: 2.4% !important; }
    .choice-5:nth-child(5) { top: 60% !important; left: 2.4% !important; }
    .choice-5 {
        width: 3% !important;
        height: 2.6% !important;
    }
    /* seq_56번 일 경우 기본형 */
    .choice-5[data-goods-seq="56"] {
        width: 10% !important;
        height: 8% !important;
    }
    .choice-5[data-goods-seq="56"]:nth-child(1) { top: 56.2% !important; left: 70.4% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(2) { top: 56.2% !important; left: 82.5% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(3) { top: 56.2% !important; left: 95% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(4) { top: 56.2% !important; left: 107.5% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(5) { top: 56.2% !important; left: 120% !important; }
}

@media screen and (max-width: 414px) {
    .choice-5:nth-child(1) { top: 45.2% !important; left: 2.4% !important; }
    .choice-5:nth-child(2) { top: 48.7% !important; left: 2.4% !important; }
    .choice-5:nth-child(3) { top: 52.2% !important; left: 2.4% !important; }
    .choice-5:nth-child(4) { top: 55.8% !important; left: 2.4% !important; }
    .choice-5:nth-child(5) { top: 59.3% !important; left: 2.4% !important; }
    .choice-5 {
        width: 3% !important;
        height: 2.45% !important;
    }
    
    /* seq_56번 일 경우 */
    .choice-5[data-goods-seq="56"] {
        width: 10% !important;
        height: 7.5% !important;
    }
    .choice-5[data-goods-seq="56"]:nth-child(1) { top: 56% !important; left: 70.4% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(2) { top: 56% !important; left: 82.5% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(3) { top: 56% !important; left: 95% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(4) { top: 56% !important; left: 107.5% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(5) { top: 56% !important; left: 120% !important; }
}

@media screen and (max-width: 393px) {
    .choice-5:nth-child(1) { top: 45.8% !important; }
    .choice-5:nth-child(2) { top: 48.8% !important; }
    .choice-5:nth-child(3) { top: 52% !important; }
    .choice-5:nth-child(4) { top: 55.3% !important; }
    .choice-5:nth-child(5) { top: 58.2% !important; }
    .choice-5 {
        height: 2.3% !important;
    }
   /* seq_56번 일 경우 */
    .choice-5[data-goods-seq="56"]:nth-child(1) { top: 55.6% !important; left: 70.4% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(2) { top: 55.6% !important; left: 82.5% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(3) { top: 55.6% !important; left: 95% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(4) { top: 55.6% !important; left: 107.5% !important; }
    .choice-5[data-goods-seq="56"]:nth-child(5) { top: 55.6% !important; left: 120% !important; }
    .choice-5[data-goods-seq="56"] {
        height: 6.8% !important;
    }
}

@media screen and (max-width: 330px) {
    .choice-5:nth-child(1) { top: 46.2% !important; }
    .choice-5:nth-child(2) { top: 48.8% !important; }
    .choice-5:nth-child(3) { top: 51.5% !important; }
    .choice-5:nth-child(4) { top: 54.3% !important; }
    .choice-5:nth-child(5) { top: 57% !important; }
    .choice-5 {
        height: 2% !important;
    }
    .choice-5[data-goods-seq="56"] {
        height: 6% !important;
    }
}

@media screen and (max-width: 270px) {
    .choice-5:nth-child(1) { top: 47% !important; }
    .choice-5:nth-child(2) { top: 49.2% !important; }
    .choice-5:nth-child(3) { top: 51.2% !important; }
    .choice-5:nth-child(4) { top: 53.5% !important; }
    .choice-5:nth-child(5) { top: 55.8% !important; }
    .choice-5 {
        height: 1.8% !important;
    }
    .choice-5[data-goods-seq="56"] {
        height: 5% !important;
    }
}

/* 6지선다 반응형 스타일 */
@media screen and (max-width: 458px) {
    .choice-6:nth-child(1) { top: 43.6% !important; }
    .choice-6:nth-child(2) { top: 48% !important; }
    .choice-6:nth-child(3) { top: 51.7% !important; }
    .choice-6:nth-child(4) { top: 55.2% !important; }
    .choice-6:nth-child(5) { top: 58.7% !important; }
    .choice-6:nth-child(6) { top: 62% !important; }
    .choice-6 {
        width: 3% !important;
        height: 3% !important;
    }
}

@media screen and (max-width: 414px) {
    .choice-6:nth-child(1) { top: 44.2% !important; }
    .choice-6:nth-child(2) { top: 48.2% !important; }
    .choice-6:nth-child(3) { top: 51.5% !important; }
    .choice-6:nth-child(4) { top: 54.8% !important; }
    .choice-6:nth-child(5) { top: 58% !important; }
    .choice-6:nth-child(6) { top: 61% !important; }
    .choice-6 {
        width: 3% !important;
        height: 2.5% !important;
    }
}

@media screen and (max-width: 393px) {
    .choice-6:nth-child(1) { top: 44.6% !important; }
    .choice-6:nth-child(2) { top: 48.4% !important; }
    .choice-6:nth-child(3) { top: 51.4% !important; }
    .choice-6:nth-child(4) { top: 54.3% !important; }
    .choice-6:nth-child(5) { top: 57% !important; }
    .choice-6:nth-child(6) { top: 59.7% !important; }
    .choice-6 {
        width: 3% !important;
        height: 2.2% !important;
    }
}

@media screen and (max-width: 330px) {
    .choice-6:nth-child(1) { top: 45.3% !important; }
    .choice-6:nth-child(2) { top: 48.7% !important; }
    .choice-6:nth-child(3) { top: 51.2% !important; }
    .choice-6:nth-child(4) { top: 53.7% !important; }
    .choice-6:nth-child(5) { top: 56.1% !important; }
    .choice-6:nth-child(6) { top: 58.6% !important; }
    .choice-6 {
        width: 3% !important;
        height: 2% !important;
    }
}

@media screen and (max-width: 270px) {
    .choice-6:nth-child(1) { top: 46% !important; }
    .choice-6:nth-child(2) { top: 48.7% !important; }
    .choice-6:nth-child(3) { top: 50.9% !important; }
    .choice-6:nth-child(4) { top: 52.9% !important; }
    .choice-6:nth-child(5) { top: 55% !important; }
    .choice-6:nth-child(6) { top: 57% !important; }
    .choice-6 {
        width: 3% !important;
        height: 1.7% !important;
    }
}