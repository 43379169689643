.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    z-index: 10;
}

.modal-content {
    background-color: white; 
    border-radius: 10px;
    width: 100%;
    max-width: 500px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
}

.modal-wrapper {
    padding: 32px 20px 16px 20px;
}

.modal-title {
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.4px;
}

.modal-message {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 145%;
    color: #525458;
    padding-top: 8px;
}
.modal-buttons {
    display: flex;
    padding: 16px;
    gap: 8px;
}
.modal-button-wrapper {
    width: 100%;
}
.modal-button-no, .modal-button-yes {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    border: none;
}
.modal-button-no{
    background: #F4F4F5;
    color: #3C3D40;
}
.modal-button-yes{
    background: #01BCC8;
    color: #FFFFFF;
}

/*삭체 취소 팝업 */
.dimmed{
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color:  rgba(0, 0, 0, 0.50);
    max-width: 550px;
    width: 100%;
    height: 100%;
    z-index: 10000;
}
.action_popup{
    position: fixed;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 550px;
    width: 100%;
    z-index: 10001;

}
.action_list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:8px;
    padding: 8px;
}

.action_button{
    background: var(--neutral-white);
    border-radius: 13px;
    width: 100%;
    height: 57px;

    font:500 20px/140% 'Pretendard';
    letter-spacing: -0.5px;
}
.action_delete{
    color:#FF3A30;
}
.action_cancel{
    color:#007AFF;
}

/* full  레이어 팝업  */
.default_popup{
    max-width: 550px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
}


.modal_wrap {
    max-height: 100vh;
    overflow: auto;
    height: 100%;
}

.modal_content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    align-self: center;
    background-color: white;
}

.modal_body {
    padding: 56px 20px;
    margin-top: 8px;
}
.modal_foot{
    max-width: 550px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 8px 20px;
    background-color: var(--neutral-white);
    display: flex;
    align-items: center;
    gap:8px;
}