
.terms_container .content_body {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.terms_container .terms_text {
    padding: 0 4px;
}

.terms_container .head_title {
    color: var(--neutral-900);
    font: var(--h2);
    letter-spacing: var(--h2-letter-spacing);
}

.terms_article {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.terms_article {
    font: var(--p4);
    letter-spacing: var(--p4-letter-spacing);
}

.terms_article .terms_desc {
    color: var(--neutral-600);
    font-weight: 400;
}

.terms_article .terms_desc {
    counter-reset: detail;
}

.terms_article .terms_desc p:before {
    counter-increment: detail;
    content: counter(detail) ". ";
    margin-right: 8px;
}

.terms_article .terms_desc p span:before {
    counter-increment: detail;
    content: attr(data-symbol) " ";
    font-size: 14px;
}

.table_container {
    border-radius: 14px;
    border: 1px solid var(--neutral-100)
}
.table_container .terms_table{
    table-layout: fixed;
}
.table_container tbody td{
    color: var(--neutral-600);
}
.table_container tr{
    border-bottom: 1px solid var(--neutral-100);
}
.table_container td{
    vertical-align: middle;
    padding:8px 8px;
    border-right: 1px solid var(--neutral-100)
}
.table_container td:last-child{
    border-right: none;
}
.table_container .border_none{
    border:none
}
.table_container tbody td{
    font-weight: 400;
}