
.alert{
    margin-bottom: 21px;
}

.alert_content{
    padding: 2px 0 ;
    font:var(--p3-1);
    letter-spacing: var(--p3-letter-spacing);
}

.alert_item{
    border-bottom: 1px solid #F8F8F9;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.alert_time{
    padding: 2px 0 ;
    font:var(--12-label-md);
    letter-spacing: var(--caption1-letter-spacing);
    color: #686B70;
}