.close_container {
    padding: 16px 20px;
    text-align: right;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 550px;
    background: var(--neutral-white);
}

.login_link {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #01BCC8;
    padding-left: 4px;
}

.header_container, .login_prompt, .join_image_container {
    text-align: center;
}

.header_container {
    margin-top: 64px;
}

.header_container .logo {
    width: 98px;
    height: 88px;
    margin: 0 auto;
}

.header_container .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.login_prompt {
    margin-top: 32.5px;
}

.login_prompt {
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    color: #7F8287;
}

.tagline {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #212223;
}

.product_name {
    font-size: 26px;
    font-weight: 900;
    line-height: 26px;
    color: #212223;
    padding-top: 6px;
}

.header_content {
    margin-top: 16px;
}

.join_image_container {
    margin-top: 8px;
}

.image_group {
    position: relative;
    margin-top: 85px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.join_image_item {
    width: 100%;
    aspect-ratio: 39 / 7;
    object-fit: cover;
}

/* ------Agreement------ */
.agreement_item, .instruction_container {
    display: flex;
    align-items: center;
}

.agreement_items {
    margin-top: 20px;

}

.agreement_item_highlighted {
    margin-top: 24px;
    border: 1px solid #FFFFFF;
    gap: 8px;
    border-radius: 10px;
    background: #F8F8F9;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    height: 54px;
}

.agreement_item_highlighted .agreement_text {
    font-weight: 600;
    color: var(--neutral-black)
}

.agreement_item {
    margin-top: 24px;
    gap: 8px;
    padding: 0px 12px;
}

.arrow_icon {
    margin-left: auto;
}

.registration_message {
    margin-top: 20px;
}

.agreement_text {
    font: var(--p4-1);
    line-height: 15px;
    letter-spacing: -0.025em;
    color: var(--neutral-800);
}

