:root {
    --label-line-height: 100%;
    --border-width:1px;
    --h1: 700 32px/120% 'Pretendard', sans-serif;
    --h1-1: 400 32px/130% 'Pretendard', sans-serif;
    --h1-letter-spacing: -0.8px;

    --h2: 700 26px/140% 'Pretendard', sans-serif;
    --h2-letter-spacing: -0.65px;


    --h3: 700 22px/134% 'Pretendard', sans-serif;
    --h3-letter-spacing: -0.55px;

    --20-lable-extra: 800 20px/100% 'Pretendard', sans-serif;
    --h4: 600 20px/134% 'Pretendard', sans-serif;
    --h4-letter-spacing: -0.5px;

    --p1: 700 18px/126% 'Pretendard', sans-serif;
    --p1-letter-spacing: -0.45px;

    --p2: 700 17px/140% 'Pretendard', sans-serif;
    --p2-letter-spacing: -0.4px;


    --16-lable-medium:500 16px/100% 'Pretendard', sans-serif;
    --p3: 700 16px/140% 'Pretendard', sans-serif;
    --p3-1: 600 16px/140% 'Pretendard', sans-serif;
    --p3-2: 400 16px/140% 'Pretendard', sans-serif;
    --p3-letter-spacing: -0.4px;


    --15-lable-semi:600 15px/100% 'Pretendard', sans-serif;
    --p4: 600 15px/145% 'Pretendard', sans-serif;
    --p4-1: 500 15px/145% 'Pretendard', sans-serif;
    --p4-2: 400 15px/145% 'Pretendard', sans-serif;
    --p4-letter-spacing: -0.375px;

    --body1: 600 14px/145% 'Pretendard', sans-serif;
    --body1-1: 500 14px/145% 'Pretendard', sans-serif;
    --body1-2: 400 14px/145% 'Pretendard', sans-serif;
    --body1-letter-spacing: -0.35px;

    --body2: 700 13px/145% 'Pretendard', sans-serif;
    --body2-1: 500 13px/145% 'Pretendard', sans-serif;
    --body2-2: 400 13px/145% 'Pretendard', sans-serif;
    --body2-letter-spacing: -0.325px;

    --12-label-bold: 700 12px/100% 'Pretendard', sans-serif;
    --12-label-md: 500 12px/100% 'Pretendard', sans-serif;
    --caption1: 400 12px/145% 'Pretendard', sans-serif;
    --caption1-letter-spacing: -0.3px;

    --caption2: 400 11px/145% 'Pretendard', sans-serif;
    --caption2-letter-spacing: -0.275px;

    --tiny1: 400 10px/140% 'Pretendard', sans-serif;
    --tiny1-letter-spacing: -0.25px;


    --neutral-white:#fff;
    --neutral-black: #000;
    --neutral-10:#F8F8F9;
    --neutral-50:#F4F4F5;
    --neutral-100:#EEEEEF;
    --neutral-200:#D0D1D3;
    --neutral-300:#B4B5B9;
    --neutral-400:#9C9EA3;
    --neutral-500:#7F8287;
    --neutral-600:#686B70;
    --neutral-700:#525458;
    --neutral-800:#3C3D40;
    --neutral-900:#212223;


    --primary-50:#DEFDFF;
    --primary-100:#ABF2F6;
    --primary-200:#89E5EB;
    --primary-300:#4ECFD6;
    --primary-400:#29C5CE;
    --primary-500:#01BCC8;
    --primary-600:#03ABB6;
    --primary-700:#08969D;
    --primary-800:#057A7F;
    --primary-900:#04575C;

    --secondary-50:#E9EBF8;
    --secondary-100:#BEBDFF;
    --secondary-200:#9E9DFF;
    --secondary-300:#7270FF;
    --secondary-400:#5654FF;
    --secondary-500:#2C29FF;
    --secondary-600:#2825E8;
    --secondary-700:#1F1DB5;
    --secondary-800:#18178C;
    --secondary-900:#12116B;

    --rsbs-overlay-rounded:20px 20px 0 0
}