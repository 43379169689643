/* ----공지사항---- */
.Intro_img {
    width: 100%;

}

.impact-container {
    margin-top: 24px;
}

.impact-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    color: #000000;
}

.impact-content {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21.75px;
    color: #686B70;
}

/*나의 리포트 , 나의 심리 검사 , 결제 정보 */
.my_list_section .forward_type .assessment_details {
    order: 2;
    padding-right: 0;
}

.my_list_section .my_list .assessment_details {
    display: flex;
    flex-direction: column;
    padding-block: 4px;
    width: calc(100% - 96px);
}

.my_list_section .my_test .assessment_details {
    padding-block: 10px;
}

.my_list_section .my_list .assessment_meta {
    gap: 0;
}

.my_list_section .my_list .assessment_info {
    gap: 12px;
    border-bottom: 1px solid var(--neutral-10);
    padding: 20px 24px;
}

.my_list_section .my_list .assessment_title {
    font: var(--p3-1);
    letter-spacing: var(--p3-letter-spacing);
}

.my_list_section .my_list .assessment_code {
    font: var(--12-label-md);

    letter-spacing: var(--caption1-letter-spacing);
    line-height: var(--label-line-height);
}

.my_list_section .resend_button {
    margin-top: auto
}

.my_list_section .assessment_badge {
    margin-bottom: auto;
}

/*나의 문의/신청*/
.btm_border_box {
    border-bottom: 1px solid var(--neutral-10);
    padding: 20px 24px;
}

.dropdown_item {
    display: flex;
    flex-direction: column;
}

.dropdown_item.open .drop_content {
    max-height: 10000px;
    overflow: hidden;
}

.dropdown_item .badge_item {
    padding: 2px 0;
}

.dropdown_item .drop_title {
    margin-top: 8px;
    font: var(--p3-1);
    letter-spacing: var(--p3-letter-spacing);
    padding: 2px 0;
}

.dropdown_item .drop_content {
    overflow: hidden;
    font: var(--body1-2);
    letter-spacing: var(--body1-letter-spacing);
    color: var(--neutral-700);
    transition: all 0.3s;

}


.dropdown_item .drop_content .answer_box {
    border-radius: 20px;
    padding: 20px;
    margin-block: 8px 4px;
    background-color: var(--neutral-50);
}

.dropdown_item .drop_etc {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font: var(--12-label-md);
    letter-spacing: var(--caption1-letter-spacing);
    line-height: var(--label-line-height);
    color: var(--neutral-600);
    padding-top: 4px;

}

.dropdown_item .group_line .group_item:after {
    height: 10px;
}


/* 바텀 시트 리스트 */
[data-rsbs-overlay] {
    border-radius: 20px 20px 0 0 !important;
    --rsbs-overlay-h: auto;
    z-index: 60 !important;
}

[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after {
    z-index: 21 !important;
}

[data-rsbs-header]:before {
    --rsbs-handle-bg: #9C9EA3;
    width: 38px !important;
    top: 12px !important;

}

[data-rsbs-has-header='false'] [data-rsbs-header] {
    padding-top: 16px !important;
    padding-bottom: 0;

}

.sheet_list {
    margin-bottom: 25px;
}

.sheet_list .sheet_title {
    padding: 16px 20px;

    font: var(--h4);
    letter-spacing: var(--h4-letter-spacing);
}

.sheet_list .sheet_item {
    padding: 0 20px;
    font: 400 15px/48px 'Pretendard';
    letter-spacing: -0.375px;
    cursor: pointer;
}

/* 공지사항 상세 */
.board_detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.board_detail .board_title_box {
    padding: 0 24px 0 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.board_detail .board_title_box .board_title {
    color: var(--neutral-900);
    font: var(--h2);
    letter-spacing: var(--h2-letter-spacing);
}

.board_detail .board_title_box .board_date {
    color: var(--neutral-600);
    font: var(--body1-1);
    letter-spacing: var(--body1-letter-spacing);
}
.board_detail .board_content {
    padding: 0 4px;
}
.board_detail .board_bottom {
    display: flex;
    padding: 0 4px;
}

.board_detail .board_bottom .board_file_btn {
    display: flex;
    align-items: center;
    gap:2px;
    color:var(--neutral-600);
    text-decoration: underline;
    text-underline-offset: 3px;
    font: var(--p4-1);
    letter-spacing: var(--p4-letter-spacing);
}

