.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000B2;
    z-index: 2000;
}

/*.menu {*/

/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    background-color: #fff;*/
/*    z-index: 2000;*/
/*    width: 82%;*/
/*    height: 100%;*/
/*}*/
.menu_header {
    display: flex;
    padding: 21px 16px 16px 25px;
    justify-content: space-between
}

.menu_icons {
    display: flex;
    gap: 16px;
}

.user_name {
    font-size: 20px;
    font-weight: 800;
    line-height: 126%;
    letter-spacing: -0.5px;
}
.profile_image{
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.profile_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.edit_info {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: var(--body1-letter-spacing);
    color: #7F8287;
    cursor: pointer;
}

.user_info {
    display: flex;
    justify-content: space-between;
    padding: 7px 24px 28px 24px;
    align-items: center;
    margin-top: 20px;
}

.info_item {
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
    border-bottom: 1px solid #F8F8F9;
    height: 76px;
    align-items: center;
}

.info_value {
    display: flex;
    gap: 8px;
    color: #01BCC8;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: var(--p3-letter-spacing);
}

.info_label {
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: var(--p3-letter-spacing);
}

.arrow_icon {
    display: flex;
    align-items: center;
}

.notifications {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.notification_label {
    position: relative;
    min-width: 106px;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: var(--body1-letter-spacing);
    color: #000000;
    padding: 13px 10px;
    text-align: center;

}

.notification_item {
    display: flex;
}

.notifications .group_item:after {
    margin: 0;
    background-color:var(--neutral-300)
}

.notifications .new_icon:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50em;
    position: absolute;
    top: 9px;
    right: 20px;
    background-color: var(--primary-500);
}

.notification_icon_line {
    width: 1px;
    height: 10px;
}

.social_icons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}
.social_icons button{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.social_icons img{
    width: 35px;
    height: 35px;
}

.edit_info_wrapper {
    display: flex;
    padding-top: 6px;
}