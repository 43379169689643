.footer_container {
    text-align: center;
    padding: 8px 0;
}


.footer_brand {
    display: flex;
    justify-content: center;
    font: var(--caption2);
    line-height: var(--label-line-height);
    letter-spacing: var(--caption2-letter-spacing);
    color: var(--neutral-400);
    cursor: pointer;

}

.footer_rights {
    font: var(--tiny1);
    letter-spacing: var(--tiny1-letter-spacing);
    color: var(--neutral-400);
}

.footer_info {
    margin-top: 8px;
    font-size: 11px;
    font-weight: 400;
    line-height: 15.95px;
    color: #9C9EA3;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.footer_layer {
    margin-top: 8px;
    cursor: pointer;
    display: block;
}

.footer_toggle {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
}

.footer_whether {
    width: 12px;
    height: 12px;
    transition: all 0.3s linear;
}

.Footer_img {
    width: 85px;
    height: 24px;
}

.footer_layer_img {
    width: 108.816px;
    height: 20px;
}


/* 푸터 드롭다운 acive */
.footer_container.active .footer_whether {
    transform: rotateX(180deg);
}

.footer_container.active .footer_info {
    max-height: 300px;
    margin-bottom: 8px;
}
