body {
    margin: 0;
    padding: 0;
    font-family: 'Pretendard', sans-serif;
}

* {
    font-family: 'Pretendard', sans-serif;
}

*,
::after,
::before {
    box-sizing: border-box;
}

#App {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    background-color: #fff;
}


#view_wrapper {

    line-height: 1;
    max-width: 550px;
    width: 100vw;
    height: 100%;
    overflow: auto;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
}

.content {
    border-radius: 0;
    min-height: max-content;
    /*min-height: 100vh;*/
    padding-top: 56px;
    height: auto;
}

.content_body {
    padding: 8px 24px 40px 24px;
    padding-bottom: 15vh;
}

.inner_btm {
    padding-bottom: 72px;
}

/* radius 14 input */
.form_group {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.default_input .label {
    margin-bottom: 8px;
    display: block;
}

.default_input textarea {
    resize: none;
}

.default_input input[type="text"],
.default_input input[type="password"],
.default_input input[type="number"],
.default_input textarea,
.default_input .form_input, .default_input .form_input.active {
    text-align: left;
    transition: all 0.3s;
    width: 100%;
    border-radius: 14px;
    line-height: 46px;
    border: 1px solid #EEEEEF;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: var(
            --body1-letter-spacing);
    color: var(--neutral-black)
}

.default_input input[type="text"]:focus,
.default_input input[type="password"]:focus,
.default_input input[type="number"]:focus,
.default_input textarea:focus,
.default_input .form_input:focus {
    border-color: var(--primary-400);
}

.default_input input[type="text"].error,
.default_input input[type="password"].error,
.default_input input[type="number"].error,
.default_input .form_input.error {
    border-color: var(--primary-400);
}

.default_input input[type="text"]::placeholder,
.default_input input[type="password"]::placeholder,
.default_input input[type="number"]::placeholder,
.default_input textarea::placeholder,
.default_input .form_input {
    color: var(--neutral-400)
}

.default_input input[type="text"]:disabled,
.default_input input[type="password"]:disabled,
.default_input input[type="number"]:disabled,
.default_input textarea:disabled,
.default_input .disabled {
    border: 1px solid var(--neutral-100);
    background: var(--neutral-10);
    color:var(--neutral-300)
}


/* bottom border input */
.default_input .comb_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.default_input .count_text {
    font: 500 10px/100% 'Pretendard';
    letter-spacing: -0.25px;
    color: var(--neutral-500);
}

.default_input.border .label {
    font: var(--body1);
    letter-spacing: var(--body1-letter-spacing);
}

.default_input.border input[type="text"],
.default_input.border input[type="password"],
.default_input.border input[type="number"],
.default_input.border textarea,
.default_input.border .form_input {
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding: 0;
    height: 44px;
}

.default_input.border input[type="text"]:disabled,
.default_input.border input[type="password"]:disabled,
.default_input.border input[type="number"]:disabled,
.default_input.border textarea:disabled,
.default_input.border .disabled {

    background: var(--neutral-white);
}

/* input 안에 버튼이 존재할 경우*/
.comb_input{
    position: relative;
}

.comb_input .input_btn{
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right: 16px;
}
/* 체크박스 */
.default_chk_box   input {
    display: none;
}
.default_chk_box  label{
    cursor: pointer;
    vertical-align: middle;
    display: flex;
    font:var(--body1-1);
    letter-spacing:var (--body1-letter-spacing);
    line-height: 100%;
}
.default_chk_box  label span{
    padding-top: 3.5px;
}
.default_chk_box  label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    background: url('../../assets/img/radio_login.png') no-repeat center center /contain;
    vertical-align: middle;
    margin-right: 4px;

}
input[type="checkbox"]:checked + label:before {
    background-image: url('../../assets/img/radio_check.png')

}
/* 텍스트 사이 선 */
.group_line {
    display: flex;
    align-items: center;
}

.group_line .group_item {
    display: flex;
    align-items: center;
}

.group_line .group_item:after {
    margin: 0 6px;
    content: '';
    width: 1px;
    height: 11px;
    background-color: var(--neutral-300);
    display: block;
}

.group_line .group_item:last-child:after {
    display: none
}

.group_line .group_item:last-child:first-child:after {
    display: none
}

/* 리스트 마커 */
.marker_list {
    list-style-type: disc;
    list-style-position: inside;
    padding-inline-start: 9px;
}

.default_margin {
    margin-top: 8px;
}

.bg-light {
    background: #F2F4F6;
}

.bg-white {
    background: #FFFFFF;
}

.image_size {
    width: 100%;
    border-radius: 32px;
    object-fit: cover;
}

.line_height {
    width: 100%;
    height: 10px;
    background: #F4F4F5;
}

.error_message {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.4px;
    padding: 7.5px 12px;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
}

.error_message.nickname {
    color: #057A7F;
    background: #DEFDFF;
}

.error_message.blue {
    color: blue;
}

.title_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 16px 20px;;
}

input {
    height: 48px;
}

.Icon_alarm, .Icon_menu, .main_arrow_img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.back_img {
    width: 24px;
    height: 24px;
}

/* 말줌임표 */
.ellipsis1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    display: inline-block;
    vertical-align: bottom;
}

.ellipsis_clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-wrap: break-word;
}

.ellipsis_2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-wrap: break-word;
}

.text_line {
    height: 1px;
    width: 100%;
    background: #F8F8F9;
    margin: 16px 0px;
}


/* 토스트 */
.Toastify {
    --toastify-toast-bd-radius: 50em;
    --toastify-color-dark: rgba(60, 61, 64, 0.70);
    --toastify-toast-min-height:46px;
    --toastify-toast-width:328px;
}
.Toastify .Toastify__toast-container{
    padding: 0;
    bottom: var(--toastify-toast-bottom)!important;
    left: 50%!important;
    transform: translateX(-50%)!important;
    width: calc(100% -  32px)!important;
    max-width: var(--toastify-toast-width)!important;

}
.Toastify .Toastify__toast {
    border-radius: var(--toastify-toast-bd-radius)!important;
    padding: 0;
    backdrop-filter: blur(12px);
    margin-bottom: 8px!important;
}

.Toastify .Toastify__toast-body {
    padding: 0;
    margin: 0;
    text-align: center;
    font: var(--body1);
    letter-spacing: var(--body1-letter-spacing);
    line-height: var(--label-line-height);
}
